import {
  DELETE_AUCTION_HOUSE,
  DELETE_AUCTION_HOUSE_ERROR,
  DELETE_AUCTION_HOUSE_SUCCESS,
  GET_AUCTION_HOUSE,
  GET_AUCTION_HOUSE_ERROR,
  GET_AUCTION_HOUSE_SUCCESS,
} from './constants.auctionHouse';

export const getAuctionHouse = (params) => {
  return {
    type: GET_AUCTION_HOUSE,
    payload: { params },
  };
};
export const getAuctionHouseSuccess = (data) => ({
  type: GET_AUCTION_HOUSE_SUCCESS,
  payload: data,
});
export const getAuctionHouseError = (message) => ({
  type: GET_AUCTION_HOUSE_ERROR,
  payload: message,
});
export const deleteAuctionHouse = (params, cb) => {
  return {
    type: DELETE_AUCTION_HOUSE,
    payload: { params, cb },
  };
};
export const deleteAuctionHouseSuccess = (data) => ({
  type: DELETE_AUCTION_HOUSE_SUCCESS,
  payload: data,
});
export const deleteAuctionHouseError = (message) => ({
  type: DELETE_AUCTION_HOUSE_ERROR,
  payload: message,
});
