export const CREATE_TERMS_AND_CONDITION = 'CREATE_TERMS_AND_CONDITION';
export const CREATE_TERMS_AND_CONDITION_SUCCESS =
  'CREATE_TERMS_AND_CONDITION_SUCCESS';
export const CREATE_TERMS_AND_CONDITION_ERROR =
  'CREATE_TERMS_AND_CONDITION_ERROR';

export const GET_TERMS_AND_CONDITION = 'GET_TERMS_AND_CONDITION';
export const GET_TERMS_AND_CONDITION_SUCCESS =
  'GET_TERMS_AND_CONDITION_SUCCESS';
export const GET_TERMS_AND_CONDITION_ERROR = 'GET_TERMS_AND_CONDITION_ERROR';

export const UPDATE_TERMS_AND_CONDITION = 'UPDATE_TERMS_AND_CONDITION';
export const UPDATE_TERMS_AND_CONDITION_SUCCESS =
  'UPDATE_TERMS_AND_CONDITION_SUCCESS';
export const UPDATE_TERMS_AND_CONDITION_ERROR =
  'UPDATE_TERMS_AND_CONDITION_ERROR';
