import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteAuctionHouseError,
  deleteAuctionHouseSuccess,
  getAuctionHouseError,
  getAuctionHouseSuccess,
} from './actions.auctionHouse';
import {
  DELETE_AUCTION_HOUSE,
  GET_AUCTION_HOUSE,
} from './constants.auctionHouse';
import {
  deleteAuctionHouses,
  getAuctionHouses,
} from './httpCalls.auctionHouse';

export function* GetAuctionHouses(action) {
  const res = yield call(getAuctionHouses, action.payload);
  if (res.success) yield put(getAuctionHouseSuccess(res));
  else yield put(getAuctionHouseError(res));
}
export function* DeleteAuctionHouses(action) {
  const res = yield call(deleteAuctionHouses, action?.payload?.params);
  if (res.success) {
    yield put(deleteAuctionHouseSuccess(res));
  } else yield put(deleteAuctionHouseError(res));
  action?.payload?.cb?.(res);
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_AUCTION_HOUSE, GetAuctionHouses)],
    [yield takeEvery(DELETE_AUCTION_HOUSE, DeleteAuctionHouses)]
  );
}
