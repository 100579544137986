/* eslint-disable no-unused-vars */

import axiosInstance from 'helpers/axiosInstance';

export async function getAuctionHouses(params) {
  try {
    const res = await axiosInstance.get('/auction-house/all-auction-houses', {
      ...params,
    });
    return res;
  } catch (err) {
    return err;
  }
}
export async function deleteAuctionHouses(auctionHouseIds) {
  try {
    const res = await axiosInstance.delete('/auction-house/delete-zone', {
      data: { auction_house_ids: auctionHouseIds },
    });
    return res;
  } catch (err) {
    return err;
  }
}
